<script setup>
import AppQuotesheetTableListItem from "@/Components/AppQuotesheetTableListItem.vue";
import {PlusCircleIcon} from "@heroicons/vue/outline";
import {Inertia} from "@inertiajs/inertia";
import {computed, ref} from "vue";

const props = defineProps({
  quotesheets: {
    type: Object,
    default: null,
  },
  lead_id: {
    type: Number,
    required: true
  }
});


const creating = ref(false);
function createQuotesheet() {
  if (creating.value) return;
  creating.value = true;
  Inertia.get(route('lead.quotesheets.create', props.lead_id), {}, {
    onFinish: () => {
      creating.value = false;
    }
  });
}

const appQuotesheets = computed(() => {
    return props.quotesheets.data.filter(i => !i.legacy_id);
});
</script>

<template>

  <div class="flex flex-col">
    <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="inline-block min-w-full py-2 align-middle md:px-6">
        <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
          <div class="md:flex items-center justify-between px-5 py-2 bg-white">
            <h1 class="text-xl font-semibold bg-white text-gray-900">
              Quotesheets
            </h1>
            <button @click.prevent="createQuotesheet" type="button" class="w-full md:w-auto btn-primary inline-flex items-center px-5 py-2 text-sm">
              <PlusCircleIcon class="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
              <span>Create Quotesheet</span>
            </button>
          </div>
          <table class="min-w-full divide-y divide-gray-300 border-t border-t-gray-300">
            <thead class="bg-slate-200">
            <tr>
              <th scope="col" class="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900">Date</th>
              <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">Prepared For</th>
              <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">Application</th>
              <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">Options</th>
            </tr>
            </thead>
            <tbody class="divide-y divide-gray-200 bg-white">
            <app-quotesheet-table-list-item v-for="(quotesheet, i) in appQuotesheets" :key="`quotesheet_${quotesheet.id}`" :quotesheet="quotesheet" :lead_id="props.lead_id"></app-quotesheet-table-list-item>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
